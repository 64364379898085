export enum SONG_IDs {
    PIPPI = "pippi",
    ACOUSTIC = "acoustic",
    ELECTRO = "electro",
    TROLLMOR = "trollmor",
    DEADLYMEADLY = "deadly-meadly",
    SOUNDEFFECTS = "soundeffects",
    PERCUSSION = "percussion",
    POP = "pop",
    BLINKA = "blinka",
    MOTOR = "motor",
    FARM = "farm",
    HUMAN = "human",
    CHURCH = "church",
    CHRISTMAS = "christmas",
    WATER = "water",
    METRONOME = "metronome",
    FUNKIFUNK = "FunkiFunk",
    ROCKAFETT = "rocka-fett",
    MIRAN = "miran",
    OLIVER = "oliver",
    SAMER = "samer",
    FIFTYBPM = "50bpm",
    SIXTY = "60bpm",
    SEVENTY = "70bpm",
    EIGHTY = "80bpm",
    NINETHY = "90bpm",
    HUNDERED = "100bpm",
    HUNDEREDTEN = "110bpm",
    HUNDEDTWENTY = "120bpm",
    OHLSSONS = "ohlssons",
}

export enum BACKINGTRACK {
    PLAY = "play-backingtrack",
    STOP = "stop-backingtrack",
    PLAY_METRONOME = "play-metronome",
    STOP_METRONOME = "stop-metronome",
}

export enum SONG_TYPES {
    MUSIC = "music",
    SOUNDBANKS = "soundbanks",
    KEY = "key",
}

export const SONG_CATEGORIES = {
    ALL: "alla",
    SOUNDBANKS: {
        PLACES: "platser",
        SOUND_EFFECTS: "ljudeffekter",
    },
    MUSIC: {
        ACOUSTIC: "akustisk",
        DISCO: "disko",
        BALLADS: "visor",
        HOLIDAYS: "högtider",
        COMP: "Komprytmer",
    },
    KEY: {
        Eb: "Eb",
        D: "D",
    },
}

export interface Song {
    title: string
    description?: string
    img?: string
    id: SONG_IDs | string
    type: SONG_TYPES
    category: string
    songPackages?: string[]
    keytone?: string
    tempo?: string
    genre?: string
    artist?: string
    hasSections?: boolean
    backingTrack: BackingTrack
    [key: string]: any
}

export interface Customer {
    id: string
    alias: string
    contactEmail: string
    paymentPlan: string
    paymentMethod: string
    licenseStartDate: Date
    licenseExpDate: Date
    instruments: Instrument[]
    songPackages: string[]
    [key: string]: any
}

export interface UserData {
    id: string
    email: string
    created: Date
    lastSignedIn?: Date
    connectedCustomerId: string
    isAdmin: boolean
    [key: string]: any
}

export interface Instrument {
    deviceId: string
    type: InstrumentType
    serialNr: string
    color: string
    mode?: ModeType
    currentSong?: {
        id?: string
        songType?: SONG_TYPES | null
        sound?: string | null
        category?: string | null
        key?: string | null
    }
}

export type ModeType = "simple" | "hard"

export type InstrumentType = "buttons" | "joysticks" | "touch" | "hybrid" | ""
export interface BackingTrack {
    src: string
    title: string
}
